import { DialogService } from 'aurelia-dialog';
import { autoinject } from 'aurelia-framework';
import { SalesOrderDialog } from './salesorder-dialog';
import { ErrorService } from 'services/error-service';

@autoinject
export class SalesOrderView {
  constructor(
    private dialogService: DialogService,
    private errorService: ErrorService
  ) {}

  protected newSalesOrder() {
    try {
      void this.dialogService
        .open({
          viewModel: SalesOrderDialog,
          position: () => {
            return {
              top: 0,
            };
          },
        })
        .whenClosed(() => {
          document.querySelector('html').style.overflowY = null;
        });
    } catch (error) {
      this.errorService.handleError(error);
    }
  }
}
