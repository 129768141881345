import { autoinject, bindable, observable } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { CertificateFormData } from './certificate-dialog';
import { MooringStationListItem } from 'models/MooringStation';
import { MooringStationService, ErrorService, MooringCertificateService } from 'services';
import {
  CertificateProducer,
  CheckDuplicateMooringCertificate,
  MooringCertificateReliabilityClass,
  MooringCertificateTemplate,
} from 'models';
import { MooringCertificateTemplateService } from 'services/mooring-certificate-template-service';
import { ValidationController, ValidationRules, Validator } from 'aurelia-validation';

@autoinject
export class CertificateDetailedForm {
  @bindable
  protected isLocked = false;

  @bindable
  readonly model: CertificateFormData;
  modelChanged(model: CertificateFormData) {
    if (!model) return;
    this.form = JSON.parse(JSON.stringify(model));
  }

  @bindable readonly onSave: (data: { data: CertificateFormData }) => void;
  @bindable readonly onCancel: () => void;
  @bindable readonly onDelete: () => void;

  @bindable
  @observable()
  protected certificateProducer: CertificateProducer;
  certificateProducerChanged(certificateProducer: CertificateProducer) {
    if (certificateProducer == undefined || certificateProducer == null) return;
    if (certificateProducer == CertificateProducer.MORENOT) {
      this.morenotProducesCertificate = true;
    } else {
      this.morenotProducesCertificate = false;
    }
  }

  @bindable
  isSaving = false;

  protected reliabilityClasses = [
    {
      Name: 'II',
      Value: 2,
    },
    {
      Name: 'III',
      Value: 3,
    },
  ];

  protected isDuplicate = false;

  protected templates: MooringCertificateTemplate[] = [];

  protected morenotProducesCertificate: boolean = false;

  protected form: CertificateFormData;

  protected checkDuplicateData: CheckDuplicateMooringCertificate = new CheckDuplicateMooringCertificate();

  protected mooringStations: MooringStationListItem[] = [];

  protected fileMetaData: any = undefined;

  private resetForm() {
    this.form = {
      SupplierId: undefined,
      ArticleProductId: undefined,
      BatchNumber: undefined,
      BatchNumberTo: undefined,
      DateOfGoodsReceipt: new Date(),
      InternalOrderNumber: undefined,
      Quantity: undefined,
      MooringStationId: undefined,
      ReliabilityClass: MooringCertificateReliabilityClass.II,
      //
      TemplateId: undefined,
      ProducerDocumentationAttachmentId: undefined,
      ProducerDocumentationAttachment: undefined,
      CertificateAttachmentId: undefined,
      CertificateAttachment: undefined,
      OverrideCertificateProducer: undefined,
    };
  }

  constructor(
    private mooringStationService: MooringStationService,
    private templateService: MooringCertificateTemplateService,
    private mooringCertificateService: MooringCertificateService,
    private validationController: ValidationController,
    private validator: Validator,
    private t: I18N,
    private errorService: ErrorService
  ) {
    this.validationController.reset();
    this.resetForm();
    void this.getMooringStations();
  }

  protected applyValidationRules() {
    const rules = ValidationRules.ensure('BatchNumber')
      .required()
      .withMessage(this.t.tr('general.requiredField'))
      .ensure('MooringStationId')
      .required()
      .withMessage(this.t.tr('general.requiredField'))
      .min(1)
      .withMessage(this.t.tr('general.requiredField'))
      .ensure('DateOfGoodsReceipt')
      .required()
      .withMessage(this.t.tr('general.requiredField'))
      .ensure('ReliabilityClass')
      .required()
      .withMessage(this.t.tr('general.requiredField'))
      .min(1)
      .withMessage(this.t.tr('general.requiredField'));

    if (this.morenotProducesCertificate) {
      rules.ensure('TemplateId').required().withMessage(this.t.tr('general.requiredField')).min(1);
      if (!this.form.ProducerDocumentationAttachmentId) {
        rules
          .ensure('InternalOrderNumber')
          .required()
          .withMessage(this.t.tr('general.requiredField'))
          .ensure('Quantity')
          .required()
          .withMessage(this.t.tr('general.requiredField'))
          .min(1)
          .withMessage(this.t.tr('validation.mustBeGreaterOrEqualToValue', { value: 1 }))
          .ensure('ProducerDocumentationAttachment')
          .required()
          .withMessage(this.t.tr('general.requiredField'));
      }
    }

    rules.on(this.form);
  }

  attached() {
    this.applyValidationRules();
  }

  private async validate() {
    this.applyValidationRules();
    await this.validator.validateObject(this.form);
    const result = await this.validationController.validate({ object: this.form });
    return result.valid;
  }

  protected async getTemplates() {
    if (this.form.MooringStationId == undefined) return [];
    this.templates = await this.templateService.getForMooringStation(this.form.MooringStationId);
  }

  protected setMooringStation(v: number | undefined) {
    this.form.MooringStationId = v;
    this.form.TemplateId = undefined;
    void this.getTemplates();
  }

  setCertificateAttachment(file: File) {
    this.form.CertificateAttachment = file;
  }

  setProducerDocumentationAttachment(file: File) {
    this.form.ProducerDocumentationAttachment = file;
  }

  setReliabilityClass(v: number) {
    this.form.ReliabilityClass = v;
  }

  private async getMooringStations() {
    this.mooringStations = await this.mooringStationService.getAll();
    await this.getTemplates();
  }

  protected async submit() {
    try {
      if (!(await this.validate())) return;
      if (this.isDuplicate) return;
      this.onSave({ data: this.form });
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  protected delete() {
    this.onDelete?.();
  }

  protected cancel() {
    this.onCancel?.();
  }

  protected async checkDuplicateCertificate() {
    try {
      if (
        this.form.ArticleProductId === this.model.ArticleProductId &&
        this.form.BatchNumber === this.model.BatchNumber
      ) {
        this.isDuplicate = false;
        return;
      }
      this.checkDuplicateData.ArticleProductId = this.form.ArticleProductId;
      this.checkDuplicateData.BatchNumber = this.form.BatchNumber;
      const { IsDuplicate } = await this.mooringCertificateService.checkDuplicate(this.checkDuplicateData);
      this.isDuplicate = IsDuplicate;
    } catch (error) {
      this.errorService.handleError(error);
    }
  }
}
