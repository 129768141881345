import { autoinject, bindable, containerless, observable } from 'aurelia-framework';
import { MooringCertificateService } from 'services';

@autoinject()
@containerless()
export class CertificateInfo {
  @bindable
  protected index = 0;

  @bindable
  @observable
  protected certificateId: number;

  protected async certificateIdChanged(id: number) {
    if (id) {
      const certificate = await this.certificateService.get(id);
      const name = certificate?.SupplierName?.slice(0, 40);

      if (!certificate) return;

      this.info = {
        CertificateId: certificate.Id,
        SupplierName: name.length === 40 ? name + '...' : name,
        ArticleNumber: certificate.ArticleProduct.ArticleNumber,
        BatchNumber: certificate.BatchNumber,
        BatchNumberTo: certificate.BatchNumberTo,
      };
    }
  }

  protected info: {
    CertificateId: number;
    SupplierName: string;
    ArticleNumber: string;
    BatchNumber: string;
    BatchNumberTo?: string;
  };

  constructor(private certificateService: MooringCertificateService) {}
}
